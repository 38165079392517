import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Box, Typography, TextField, IconButton, InputAdornment, Grid, TableSortLabel, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { SortingOrder } from '../../models/enums/sortingOrder';
import { ROUTES } from '../../constants/routes';
import TmPlus from '../../icons/TmPlus';
import { CategoryType } from '../../models/enums/categoryType';
import { PermissionType } from '../../models/enums/permissionType';
import { ShowForPermission } from '../common/permissions/ShowForPermission';
import { CompanyTableViewDto } from '../../models/companies/companyTableViewDto';
import { companiesService } from '../../services/companiesService';
import AcceptLabel from '../common/labels/AcceptLabel';
import DenyLabel from '../common/labels/DenyLabel';
import TelemedLabel from '../common/labels/TelemedLabel';

const PAGE_REFRESH_INTERVAL = 10000;

export const CompaniesTable = () => {
    const [state, setState] = useState({
        companies: [] as CompanyTableViewDto[],
        page: 0,
        rowsPerPage: 15,
        dataCount: 0,
        loading: true,
        sortColumn: 'id',
        sortOrder: SortingOrder.Asc,
        loadingColumn: null as string | null,
        name: '',
        inn: '',
        shouldFetch: true,
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (state.shouldFetch) {
            fetchCompanies();
            setState(prevState => ({
                ...prevState,
                shouldFetch: false,
            }));
        }
        const interval = setInterval(fetchCompanies, PAGE_REFRESH_INTERVAL);
        return () => clearInterval(interval); // Clear interval on component unmount
    }, [state.page, state.rowsPerPage, state.shouldFetch]);

    const fetchCompanies = () => {
        if (!state.shouldFetch) return;

        setState(prevState => ({ ...prevState, loading: true }));

        companiesService.getByFilter({
            page: state.page,
            pageSize: state.rowsPerPage,
            name: state.name || undefined,
            inn: state.inn,
            sortColumn: state.sortColumn,
            sortOrder: state.sortOrder,
        })
            .then(r => {
                setState(prevState => ({
                    ...prevState,
                    companies: r.data.data.items,
                    dataCount: r.data.data.count,
                    shouldFetch: false, // Reset shouldFetch after successful fetch
                }));
            })
            .catch(e => console.log(e))
            .finally(() => {
                setState(prevState => ({
                    ...prevState,
                    loading: false,
                    loadingColumn: null,
                }));
            });
    };

    const handleChangePage = (event: any, newPage: number) => {
        setState(prevState => ({
            ...prevState,
            page: newPage,
            shouldFetch: true,
        }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setState(prevState => ({
            ...prevState,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
            shouldFetch: true,
        }));
    };

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setState(prevState => ({
            ...prevState,
            [name]: value,
            page: 0,
            shouldFetch: true,
        }));
    };

    const handleRowClick = (event: React.MouseEvent, id: number) => {
        const url = ROUTES.clients.idPath.replace(':id', String(id));
        navigate(url);
    };

    const handleAddClient = () => {
        navigate(ROUTES.clients.add);
    };

    const handleSort = (column: string) => {
        const isAsc = state.sortColumn === column && state.sortOrder === SortingOrder.Asc;
        setState(prevState => ({
            ...prevState,
            sortColumn: column,
            sortOrder: isAsc ? SortingOrder.Desc : SortingOrder.Asc,
            loadingColumn: column,
            shouldFetch: true,
        }));
    };

    const renderTableSortLabel = (column: string, label: string) => (
        <TableSortLabel
            active={state.sortColumn === column}
            direction={state.sortColumn === column ? state.sortOrder : SortingOrder.Asc}
            onClick={() => handleSort(column)}
            IconComponent={state.loadingColumn === column ? () => <CircularProgress size={12} sx={{ color: '#9588e8' }} /> : undefined}
        >
            <Typography variant="body1" sx={{ color: "#7c7e92" }}>{label}</Typography>
        </TableSortLabel>
    );

    return (
        <Box>
            <Grid container pb={1} mt={2.5}>
                <Grid item xs={10} style={{ display: 'flex', alignItems: 'center' }}>

                    <TextField
                        label="Название компании"
                        name="name"
                        value={state.name}
                        onChange={handleFilterChange}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        style={{ width: 230, marginRight: 10 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setState(prevState => ({ ...prevState, page: 0, shouldFetch: true }))}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            style: {
                                borderRadius: "10px",
                            }
                        }}
                    />

                    <TextField
                        label="ИНН"
                        name="inn"
                        value={state.inn}
                        onChange={handleFilterChange}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        style={{ width: 230, marginRight: 10 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setState(prevState => ({ ...prevState, page: 0, shouldFetch: true }))}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            style: {
                                borderRadius: "10px",
                            }
                        }}
                    />

                </Grid>

                <Grid item xs={2} style={{ display: 'inline-block', alignItems: 'center' }}>
                    <ShowForPermission catalog={CategoryType.Companies} permission={PermissionType.Add}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton sx={{ display: "flex" }} className="list-item" onClick={handleAddClient}>
                                <TmPlus fill="none" stroke="white" />&nbsp;
                                <Typography fontWeight="bold" pr={1}>
                                    &nbsp;Добавить клиента
                                </Typography>
                            </IconButton>
                        </Box>
                    </ShowForPermission>
                </Grid>
            </Grid>

            <TableContainer sx={{ '.MuiTableCell-root': { py: 1.5 } }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '15%' }}>
                                {renderTableSortLabel('id', 'ID')}
                            </TableCell>
                            <TableCell sx={{ width: '15%' }}>
                                {renderTableSortLabel('name', 'Название компании')}
                            </TableCell>
                            <TableCell sx={{ width: '15%' }}>
                                {renderTableSortLabel('inn', 'ИНН')}
                            </TableCell>
                            <TableCell sx={{ width: '15%' }}>
                                {renderTableSortLabel('departmentsCount', 'Кол-во объектов')}
                            </TableCell>
                            <TableCell sx={{ width: '15%' }}>
                                {renderTableSortLabel('agreementState', 'Договор')}
                            </TableCell>
                            <TableCell sx={{ width: '15%' }}>
                                {renderTableSortLabel('isTelemed', 'Группа')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.companies.map((company) => (
                            <TableRow
                                key={company.id}
                                onClick={(event) => handleRowClick(event, company.id)}
                                sx={{ '&:hover': { cursor: 'pointer', backgroundColor: 'rgba(0, 0, 0, 0.08)' } }}
                            >
                                <TableCell sx={{ fontWeight: "bold" }}>{company.id}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{company.name}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{company.inn}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{company.departmentsCount}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    {
                                        company.agreementState?.isValid
                                            ? <AcceptLabel>Действует</AcceptLabel>
                                            : <DenyLabel>Заблокирован</DenyLabel>
                                    }
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    {company.isTelemed ? <TelemedLabel /> : ""}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography>Всего: {state.dataCount}</Typography>
                <TablePagination
                    rowsPerPageOptions={[15, 30, 50]}
                    component="div"
                    count={state.dataCount}
                    rowsPerPage={state.rowsPerPage}
                    page={state.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={"Строк на странице:"}
                    labelDisplayedRows={({ from, to, count }: any) => `Показано ${from}-${to} из ${count}`}
                />
            </Box>
        </Box>
    );
};
