import { AddCompanyDto } from "./addCompanyDto";

export interface AddCompanyFormDto {
    name: string;
    address: string;
    responsibleFio: string;
    inn: string;
    phone: string;
    isTelemed: boolean,
}

export const mapToAddCompanyDto = (formData: AddCompanyFormDto): AddCompanyDto => {
    return {
        name: formData.name,
        address: formData.address,
        responsibleFio: formData.responsibleFio,
        inn: formData.inn,
        phone: formData.phone,
        isTelemed: formData.isTelemed,
    }
};