import React, { useEffect, useReducer, useState } from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Grid,
    TextField,
    Typography,
    Autocomplete,
    FormGroup,
    Stack,
    IconButton,
    Backdrop
} from '@mui/material';
import TmFullButton from '../../common/buttons/TmFullButton';
import { useForm, Controller, FormProvider, useWatch } from 'react-hook-form';
import { CompanyShortDto } from '../../../models/companies/companyShortDto';
import ErrorAlert from '../../common/ErrorAlert';
import { TextFieldFormControl } from '../../common/form/TextFieldFormControl';
import TmCross from '../../../icons/TmCross';
import { EditMedicFormDto, emptyEditMedicFormDto, mapToAddMedicDto, mapToEditMedicDto, mapToEditMedicFormDto } from '../../../models/medics/editMedicFormDto';
import { medicsService } from '../../../services/medicsService';
import { MedicDetailsDto } from '../../../models/medics/medicDetailsDto';
import TmPlus from '../../../icons/TmPlus';
import TmTrash from '../../../icons/TmTrash';
import { companiesService } from '../../../services/companiesService';
import { departmentsService } from '../../../services/departmentsService';
import ImageUploadWithPreview from '../../common/image/ImageUploadWithPreview';
import { LookupDto } from '../../../models/common/lookup.dto';
import { ShowForPermission } from '../../common/permissions/ShowForPermission';
import { CategoryType } from '../../../models/enums/categoryType';
import { PermissionType } from '../../../models/enums/permissionType';
import DenyLabel from '../../common/labels/DenyLabel';
import AcceptLabel from '../../common/labels/AcceptLabel';
import { ConfirmDialog } from '../../common/ConfirmDialog';
import Spinner from '../../common/Spinner';
import { CheckboxFormControl } from '../../common/form/CheckboxFormControl';

interface EditMedicModalProps {
    open: boolean;
    onClose: () => void;
    onRefresh?: () => void;
    isAdding: boolean;
    medic?: MedicDetailsDto;
    companyId?: number;
}

interface DepartmentAction {
    type: 'ADD' | 'REMOVE' | 'TOGGLE' | 'RESET';
    payload?: number;
    allIds?: number[];
}

const departmentReducer = (state: number[], action: DepartmentAction): number[] => {
    switch (action.type) {
        case 'ADD':
            return [...state, action.payload!];
        case 'REMOVE':
            return state.filter(id => id !== action.payload);
        case 'TOGGLE':
            return state.includes(action.payload!) ?
                state.filter(id => id !== action.payload) :
                [...state, action.payload!];
        case 'RESET':
            return action.allIds || [];
        default:
            return state;
    }
};

export const EditMedicModal: React.FC<EditMedicModalProps> = ({
    open,
    onClose,
    onRefresh,
    isAdding,
    medic,
    companyId
}) => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [companyDepartments, setCompanyDepartments] = useState<{ [key: number]: LookupDto[] }>({});
    const [companies, setCompanies] = useState<CompanyShortDto[]>([]);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [selectedDepartmentIds, dispatch] = useReducer(departmentReducer, []);

    const methods = useForm<EditMedicFormDto>({
        defaultValues: medic ? mapToEditMedicFormDto(medic) : emptyEditMedicFormDto(companyId),
    });

    const companyDepartmentPairs = useWatch({
        control: methods.control,
        name: 'companyDepartmentPairs',
    }) || [];

    useEffect(() => {
        if (open) {
            const defaultValues = isAdding ? emptyEditMedicFormDto(companyId) : mapToEditMedicFormDto(medic!);
            methods.reset(defaultValues);

            if (medic) {
                medic.companies.forEach(company => {
                    fetchDepartmentsForCompany(company.id); // Ensure departments are loaded for each company
                });

                const initialSelectedDepartments = medic.companies.flatMap(company =>
                    company.departments.map(department => department.id)
                );
                initialSelectedDepartments.forEach(id => dispatch({ type: 'ADD', payload: id }));
                dispatch({ type: 'RESET', allIds: initialSelectedDepartments });
            } else {
                dispatch({ type: 'RESET', allIds: [] });
            }
        }
    }, [open, isAdding, medic, methods, companyId]);

    useEffect(() => {
        companiesService
            .search({
                name: companyId ? undefined : '',
                companyIds: companyId ? [companyId] : undefined,
                isTelemed: false,
                page: 0,
                pageSize: 999,
            })
            .then(r => setCompanies(r.data.data.items))
            .catch(e => console.log(e));
    }, [companyId]);

    const onSubmit = (formData: EditMedicFormDto) => {
        setLoading(true);
        const serviceCall = isAdding
            ? medicsService.add(mapToAddMedicDto(formData))
            : medicsService.edit(mapToEditMedicDto(formData));

        serviceCall
            .then(r => {
                onClose();
                if (onRefresh) {
                    onRefresh();
                }
            })
            .catch(e => {
                const error = e.response?.data?.message ?? e.message;
                showAlert(error);
            })
            .finally(() => setLoading(false));
    };

    const onSubmitError = (errors: any) => {
        showAlert("Исправьте ошибки ввода");
        console.log('Form Errors:', errors);
    };

    const showAlert = (errorMessage: string = 'Произошла ошибка') => {
        setError(errorMessage);
    };

    const handleAddRow = () => {
        methods.setValue('companyDepartmentPairs', [
            ...companyDepartmentPairs,
            { companyId: null, departmentId: null },
        ]);
    };

    const handleRemoveRow = (index: number) => {
        const currentDepartmentId = methods.getValues(`companyDepartmentPairs.${index}.departmentId`);
        if (currentDepartmentId) {
            dispatch({ type: 'REMOVE', payload: currentDepartmentId });
        }

        const updatedPairs = companyDepartmentPairs.filter((_, i) => i !== index);
        methods.setValue('companyDepartmentPairs', updatedPairs);
    };

    const fetchDepartmentsForCompany = (companyId: number) => {
        if (companyDepartments[companyId]) return; // Prevent re-fetching if already fetched

        departmentsService.get(companyId)
            .then((response) => {
                setCompanyDepartments(prev => ({
                    ...prev,
                    [companyId]: response.data.data // Store only the departments array
                }));
            })
            .catch(e => console.log(e));
    };

    const handleDepartmentChange = (index: number, newValue: LookupDto | null) => {
        const currentDepartmentId = methods.getValues(`companyDepartmentPairs.${index}.departmentId`);

        // Remove the current department from selected list if it exists
        if (currentDepartmentId) {
            dispatch({ type: 'REMOVE', payload: currentDepartmentId });
        }

        // Add the new department to selected list if one was chosen
        if (newValue) {
            dispatch({ type: 'ADD', payload: newValue.id });
        }

        // Update the form value
        methods.setValue(`companyDepartmentPairs.${index}.departmentId`, newValue?.id || null);
    };

    const handleDismiss = () => {
        if (medic) {
            Promise.resolve()
                .then(() => {
                    setLoading(true);
                    setOpenConfirmDialog(false);
                    return medicsService.dismiss(medic.id);
                })
                .then(() => {
                    methods.setValue("isDismissed", true);
                    if (onRefresh) {
                        onRefresh();
                    }
                })
                .catch(e => {
                    console.log(e);
                    setError(e.message);
                })
                .finally(() => setLoading(false));
        }
    }

    return (
        <Box>
            <FormProvider {...methods}>
                <Dialog
                    open={open}
                    onClose={onClose}
                    fullWidth
                    maxWidth={false}
                    PaperProps={{
                        sx: {
                            borderRadius: "16px",
                            width: '745px'
                        }
                    }}>
                    {error && (
                        <ErrorAlert
                            message={error}
                            onClose={() => setError('')}
                        />
                    )}
                    <DialogTitle sx={{ textAlign: 'center', marginTop: 2 }}>
                        <Box>
                            <Typography sx={{ fontSize: '32px', lineHeight: '1.2' }}>
                                {isAdding ? "Добавление" : "Редактирование"}
                            </Typography>
                            <Typography sx={{ fontSize: '32px', lineHeight: '1.2', marginTop: '0' }}>
                                медицинского работника
                            </Typography>
                        </Box>
                    </DialogTitle>
                    {!error && (
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{
                                position: 'absolute',
                                right: 16,
                                top: 16,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <TmCross fill="none" stroke="#cccdd5" />
                        </IconButton>
                    )}
                    <DialogContent sx={{ padding: 0 }}>
                        <Box component="form" noValidate sx={{ px: '36px' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={4} sm={4}>
                                    <Typography sx={{ fontSize: '24px' }}>Личные данные</Typography>
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                    <ImageUploadWithPreview
                                        onFileChange={(file) => {
                                            methods.setValue('photoFile', file || undefined);
                                            if (!file) {
                                                methods.setValue('photoUrl', undefined);
                                            }
                                        }}
                                        file={methods.watch('photoFile')}
                                        url={methods.watch('photoUrl')}
                                        error={methods.formState.errors.photoFile?.message}
                                        textColor="#9588e8"
                                        buttonClassName={"list-item-outline"}
                                    />
                                </Grid>

                                <Grid item xs={8} sm={8}>
                                    <FormGroup>
                                        <Stack spacing={2} direction="column">
                                            <Box display="flex">
                                                <TextFieldFormControl
                                                    name="lastName"
                                                    label="Фамилия"
                                                    required
                                                    maxLength={50}
                                                />
                                            </Box>
                                            <Box display="flex">
                                                <TextFieldFormControl
                                                    name="firstName"
                                                    label="Имя"
                                                    required
                                                    maxLength={50}
                                                    style={{ marginRight: 10 }}
                                                />
                                                <TextFieldFormControl
                                                    name="middleName"
                                                    label="Отчество"
                                                    required
                                                    maxLength={50}
                                                />
                                            </Box>
                                            <Box display="flex">
                                                <TextFieldFormControl
                                                    name="phoneNumber"
                                                    label="Телефон"
                                                    required
                                                    maxLength={20}
                                                    style={{ marginRight: 10 }}
                                                />
                                                <TextFieldFormControl
                                                    name="email"
                                                    label="Email"
                                                    required
                                                    type="email"
                                                    maxLength={128}
                                                    validationRules={{
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                            message: 'Невалидный email',
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        </Stack>
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={6} sm={6} mt={2}>
                                    <Typography sx={{ fontSize: '24px' }}>Клиенты и объекты</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} mt={2}>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <IconButton sx={{ display: "flex" }} className="list-item-outline" onClick={handleAddRow}>
                                            <TmPlus fill="none" stroke="#9588e8" />
                                            <Typography sx={{ fontSize: '14px' }}>
                                                &nbsp;Добавить клиента
                                            </Typography>
                                        </IconButton>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} mb={2}>
                                    <CheckboxFormControl
                                        name="isTelemed"
                                        label="Telemed"
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>

                                {companyDepartmentPairs.map((_, index) => (
                                    <Grid item xs={12} key={index}>
                                        <FormGroup>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                <Controller
                                                    name={`companyDepartmentPairs.${index}.companyId`}
                                                    control={methods.control}
                                                    rules={{ required: 'Выберите компанию' }}
                                                    render={({ field }) => (
                                                        <Autocomplete
                                                            {...field}
                                                            size="small"
                                                            options={companies}
                                                            getOptionLabel={(option) => option.name}
                                                            value={companies.find(c => c.id === field.value) || null}
                                                            onChange={(event, newValue) => {
                                                                field.onChange(newValue ? newValue.id : null);
                                                                if (newValue) fetchDepartmentsForCompany(newValue.id); // Fetch departments for selected company
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Клиент"
                                                                    placeholder="Введите от 3х букв"
                                                                    error={Boolean(methods.formState.errors?.companyDepartmentPairs?.[index]?.companyId)}
                                                                    helperText={methods.formState.errors?.companyDepartmentPairs?.[index]?.companyId?.message}
                                                                    required
                                                                />
                                                            )}
                                                            renderOption={(props, option) => (
                                                                <li {...props} key={option.id}>{option.name}</li>
                                                            )}
                                                            sx={{ flex: 1 }}
                                                        />
                                                    )}
                                                />
                                                <Controller
                                                    name={`companyDepartmentPairs.${index}.departmentId`}
                                                    control={methods.control}
                                                    render={({ field }) => {
                                                        const companyId = methods.watch(`companyDepartmentPairs.${index}.companyId`);
                                                        const departments = companyId ? companyDepartments[companyId] || [] : [];
                                                        return (
                                                            <Autocomplete
                                                                {...field}
                                                                size="small"
                                                                options={departments}
                                                                getOptionLabel={(option) => option.name}
                                                                value={departments.find(d => d.id === field.value) || null}
                                                                onChange={(event, newValue) => handleDepartmentChange(index, newValue)}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Объект"
                                                                        placeholder="Введите от 3х букв"
                                                                    />
                                                                )}
                                                                getOptionDisabled={(option) => {
                                                                    // Don't disable the currently selected option for this field
                                                                    if (option.id === field.value) return false;
                                                                    // Disable if selected in other fields
                                                                    return selectedDepartmentIds.includes(option.id);
                                                                }}
                                                                renderOption={(props, option) => (
                                                                    <li {...props} key={option.id}>{option.name}</li>
                                                                )}
                                                                sx={{ flex: 1 }}
                                                            />
                                                        );
                                                    }}
                                                />
                                                <IconButton onClick={() => handleRemoveRow(index)}>
                                                    <TmTrash fill="none" stroke="#9588e8" />
                                                </IconButton>
                                            </Box>
                                        </FormGroup>
                                    </Grid>
                                ))}

                                {!isAdding &&
                                    <>
                                        <Grid item xs={12} sm={12}>
                                            <Typography sx={{ fontSize: '24px' }}>Статус</Typography>
                                        </Grid><Grid item xs={6} sm={6}>

                                            <Typography className="rtb">
                                                {medic?.isDismissed ? <DenyLabel>Уволен</DenyLabel> : <AcceptLabel>Работает</AcceptLabel>}
                                            </Typography>
                                        </Grid><Grid item xs={6} sm={6}>
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <ShowForPermission catalog={CategoryType.Medics} permission={PermissionType.Delete}>
                                                    <TmFullButton
                                                        onClick={() => setOpenConfirmDialog(true)}
                                                        disabled={medic?.isDismissed}
                                                        sx={{ fontSize: '14px', mt: -2 }}
                                                    >
                                                        Уволить
                                                    </TmFullButton>
                                                </ShowForPermission>
                                            </Box>
                                        </Grid>
                                    </>
                                }

                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center' }} sx={{ pb: '36px', pt: 3 }}>
                        <TmFullButton onClick={methods.handleSubmit(onSubmit, onSubmitError)} width='45%'>
                            Сохранить
                        </TmFullButton>
                    </DialogActions>
                </Dialog>
            </FormProvider>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
                open={loading}
            >
                <Spinner />
            </Backdrop>
            <ConfirmDialog
                open={openConfirmDialog}
                title="Увольнение медицинского работника"
                content={
                    <>
                        Вы уверены, что хотите уволить сотрудника
                        <Typography variant="body1" align="center" fontWeight="bold">
                            {`${medic?.lastName} ${medic?.firstName} ${medic?.middleName}?`}
                        </Typography>
                    </>
                }
                onClose={() => setOpenConfirmDialog(false)}
                onConfirm={handleDismiss}
            />
        </Box >
    );
};
