import { Autocomplete, Backdrop, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import TmFullButton from "../../../common/buttons/TmFullButton";
import { Controller, FormProvider, useForm } from "react-hook-form";
import TmEmptyButton from "../../../common/buttons/TmEmptyButton";
import { TextFieldFormControl } from "../../../common/form/TextFieldFormControl";
import { CompanyDetailsDto } from "../../../../models/companies/companyDetailsDto";
import { LookupDto } from "../../../../models/common/lookup.dto";
import { useEffect, useState } from "react";
import { terminalsService } from "../../../../services/terminalsService";
import { companiesService } from "../../../../services/companiesService";
import ErrorAlert from "../../../common/ErrorAlert";
import Spinner from "../../../common/Spinner";
import { CheckboxFormControl } from "../../../common/form/CheckboxFormControl";

interface EditCompanyMainInfoDialogProps {
    open: boolean;
    company: CompanyDetailsDto;
    onClose: () => void;
    onSave: (company: CompanyDetailsDto | null) => void;
}

interface FormValues {
    name: string;
    address: string;
    inn: string;
    responsibleFio: string;
    phone: string;
    terminals: LookupDto[];
    isTelemed: boolean;
}

export const EditCompanyMainInfoDialog: React.FC<EditCompanyMainInfoDialogProps> = ({ open, company, onClose, onSave }) => {
    const [terminals, setTerminals] = useState<LookupDto[]>([]);
    const [loadingTerminals, setLoadingTerminals] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const methods = useForm<FormValues>({
        defaultValues: {
            name: company?.name || '',
            address: company?.address || '',
            inn: company?.inn || '',
            responsibleFio: company?.responsibleFio || '',
            phone: company?.phone || '',
            terminals: company?.terminals || [],
            isTelemed: company?.isTelemed || false,
        }
    });

    const { reset, control, watch } = methods;

    useEffect(() => {
        if (company) {
            reset({
                name: company.name || '',
                address: company.address || '',
                inn: company.inn || '',
                responsibleFio: company.responsibleFio || '',
                phone: company.phone || '',
                terminals: company.terminals || [],
                isTelemed: company.isTelemed || false,
            });
        }
    }, [company, reset]);

    const fetchTerminals = async (searchTerm: string = '') => {
        setLoadingTerminals(true);

        terminalsService.search({
            nameWithAddress: searchTerm,
            page: 0,
            pageSize: 10
        })
            .then(response => {
                setTerminals(response.data.data.items);
            })
            .catch(e => {
                console.log(e);
                const error = e.response.data.message ?? e.message;
                showAlert(error);
            })
            .finally(() => setLoadingTerminals(false));
    };

    const onSubmit = (data: FormValues) => {
        setLoading(true);

        companiesService.edit({
            id: company.id,
            name: data.name,
            address: data.address,
            responsibleFio: data.responsibleFio,
            inn: data.inn,
            phone: data.phone,
            terminalIds: data.terminals.map(t => t.id),
            isTelemed: data.isTelemed,
        })
            .then(r => {
                onClose();
                onSave({
                    id: company.id,
                    name: data.name,
                    address: data.address,
                    inn: data.inn,
                    responsibleFio: data.responsibleFio,
                    phone: data.phone,
                    terminals: data.terminals,
                    isTelemed: data.isTelemed,
                });
            })
            .catch(e => {
                console.log(e);
                const error = e.response.data.message ?? e.message;
                showAlert(error);
            })
            .finally(() => setLoading(false));
    };

    const showAlert = (errorMessage: string = 'Произошла ошибка') => {
        setError(errorMessage);
    };

    return (
        <Box>
            <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: 3, width: 500 } }}>
                <DialogTitle variant="h6" component="h2" textAlign="center">
                    Редактирование данных клиента
                </DialogTitle>
                {error && (
                    <ErrorAlert
                        message={error}
                        onClose={() => setError('')}
                    />
                )}
                <DialogContent>
                    <FormProvider {...methods}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <TextFieldFormControl
                                    name="name"
                                    label="Название организации"
                                    required
                                    maxLength={50}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldFormControl
                                    name="address"
                                    label="Адрес"
                                    required
                                    maxLength={50}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldFormControl
                                    name="inn"
                                    label="ИНН"
                                    required
                                    maxLength={50}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldFormControl
                                    name="responsibleFio"
                                    label="Ответственное лицо"
                                    required
                                    maxLength={50}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldFormControl
                                    name="phone"
                                    label="Телефон"
                                    required
                                    maxLength={50}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="terminals"
                                    control={control}
                                    render={({ field }) => (
                                        <Autocomplete
                                            {...field}
                                            multiple
                                            options={terminals}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            loading={loadingTerminals}
                                            onOpen={() => fetchTerminals()}
                                            onChange={(_, newValue) => field.onChange(newValue)}
                                            onInputChange={async (event, value) => {
                                                await fetchTerminals(value);
                                            }}
                                            noOptionsText="Не найдено"
                                            loadingText="Поиск..."
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Терминалы"
                                                    placeholder="Выберите терминалы"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {loadingTerminals ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CheckboxFormControl
                                    name="isTelemed"
                                    label="Telemed"
                                />
                            </Grid>
                        </Grid>
                    </FormProvider>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', mt: 1, mx: 2, mb: 3 }}>
                    <TmEmptyButton onClick={onClose} sx={{ flexGrow: 1 }} >
                        Отмена
                    </TmEmptyButton>
                    <TmFullButton onClick={methods.handleSubmit(onSubmit)} sx={{ flexGrow: 1 }} >
                        Сохранить
                    </TmFullButton>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
                open={loading}
            >
                <Spinner />
            </Backdrop>
        </Box>
    );
};