import React from 'react';
import { Box } from '@mui/material';

interface AcceptLabelProps {
    children: string;
  }

const AcceptLabel: React.FC<AcceptLabelProps> = ({ children }) => {
  return (
    <Box component="span" display="inline-block" color="#5bba64" sx={{backgroundColor: "#edfbee", borderRadius: "25px", paddingLeft: "15px", paddingRight: "15px", fontWeight: "bold", wordBreak: "break-all"}}>{children}</Box>
  );
}

export default AcceptLabel;
