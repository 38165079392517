import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, FormControlLabel, SxProps, Theme } from '@mui/material';

interface CheckboxFormControlProps {
    name: string;
    label: string;
    sx?: SxProps<Theme>;
}

export const CheckboxFormControl: React.FC<CheckboxFormControlProps> = ({ name, label, sx }) => {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <FormControlLabel
                    control={
                        <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                            style={{ color: '#7E5EA9' }}
                            sx={sx}
                        />
                    }
                    label={label}
                />
            )}
        />
    );
};
