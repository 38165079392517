import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Box, Typography, TextField, IconButton, InputAdornment, Grid, TableSortLabel, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import TmPlus from '../../../icons/TmPlus';
import { CategoryType } from '../../../models/enums/categoryType';
import { PermissionType } from '../../../models/enums/permissionType';
import { SortingOrder } from '../../../models/enums/sortingOrder';
import AcceptLabel from '../../common/labels/AcceptLabel';
import DenyLabel from '../../common/labels/DenyLabel';
import { ShowForPermission } from '../../common/permissions/ShowForPermission';
import { UserUtils } from '../../../helpers/userUtils';
import { EditDepartmentDialog } from '../../departments/edit/EditDepartmentDialog';
import { useCompanyDetailsContext } from './context/CompanyDetailsContextProvider';
import { departmentsService } from '../../../services/departmentsService';
import { DepartmentDetailsDto } from '../../../models/departments/departmentDetailsDto';

const PAGE_REFRESH_INTERVAL = 10000;

interface State {
    departments: DepartmentDetailsDto[];
    page: number;
    rowsPerPage: number;
    dataCount: number;
    loading: boolean;
    sortColumn: string;
    sortOrder: SortingOrder;
    loadingColumn: string | null;
    name: string;
    dispatcherFio: string;
    hideDismissed: boolean | null;
    shouldFetch: boolean;
}

export const DepartmentsAndDispatchersTable = () => {
    const { company } = useCompanyDetailsContext();
    const [isAddPopupOpen, setAddPopupOpen] = useState(false);
    const [state, setState] = useState<State>({
        departments: [] as DepartmentDetailsDto[],
        page: 0,
        rowsPerPage: 5,
        dataCount: 0,
        loading: true,
        sortColumn: 'name',
        sortOrder: SortingOrder.Asc,
        loadingColumn: null as string | null,
        name: '',
        dispatcherFio: '',
        hideDismissed: null as boolean | null,
        shouldFetch: true,
    });

    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        if (state.shouldFetch) {
            fetchData();
            setState(prevState => ({
                ...prevState,
                shouldFetch: false,
            }));
        }
        const interval = setInterval(fetchData, PAGE_REFRESH_INTERVAL);
        return () => clearInterval(interval); // Clear interval on component unmount
    }, [state.page, state.rowsPerPage, state.shouldFetch, state.name, state.dispatcherFio, state.hideDismissed]);

    const fetchData = () => {
        setState(prevState => ({ ...prevState, loading: true }));

        const companyId = Number(params.id);

        departmentsService.getByFilter(companyId, {
            name: state.name !== '' ? state.name : undefined,
            dispatcherFio: state.dispatcherFio !== '' ? state.dispatcherFio : undefined,
            hideDismissed: state.hideDismissed ? state.hideDismissed : undefined,
            page: state.page,
            pageSize: state.rowsPerPage,
            sortColumn: state.sortColumn,
            sortOrder: state.sortOrder,
        })
            .then(r => {
                setState(prevState => ({
                    ...prevState,
                    departments: r.data.data.items,
                    dataCount: r.data.data.count,
                }));
            })
            .catch(e => console.log(e))
            .finally(() => {
                setState(prevState => ({
                    ...prevState,
                    loading: false,
                    loadingColumn: null,
                }));
            });
    };

    const handleChangePage = (event: any, newPage: number) => {
        setState(prevState => ({
            ...prevState,
            page: newPage,
            shouldFetch: true,
        }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setState(prevState => ({
            ...prevState,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
            shouldFetch: true,
        }));
    };

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setState(prevState => ({
            ...prevState,
            [name]: value,
            page: 0,
            shouldFetch: true,
        }));
    };

    const handleAddPopupToggle = () => {
        setAddPopupOpen(!isAddPopupOpen);
    };

    const handleDepartmentRowClick = (id: number) => {
        const url = ROUTES.departments.idPath.replace(':id', String(id));
        navigate(url);
    };

    const handleTerminalRowClick = (id: number) => {
        const url = ROUTES.terminals.idPath.replace(':id', String(id));
        navigate(url);
    };

    const handleDispatcherRowClick = (id: number) => {
        const url = ROUTES.dispatchers.idPath.replace(':id', String(id));
        navigate(url);
    };

    const handleSort = (column: string) => {
        const isAsc = state.sortColumn === column && state.sortOrder === SortingOrder.Asc;
        setState(prevState => ({
            ...prevState,
            sortColumn: column,
            sortOrder: isAsc ? SortingOrder.Desc : SortingOrder.Asc,
            loadingColumn: column,
            shouldFetch: true,
        }));
    };

    const renderTableSortLabel = (column: string, label: string) => (
        <TableSortLabel
            active={state.sortColumn === column}
            direction={state.sortColumn === column ? state.sortOrder : SortingOrder.Asc}
            onClick={() => handleSort(column)}
            IconComponent={state.loadingColumn === column ? () => <CircularProgress size={12} sx={{ color: '#9588e8' }} /> : undefined}
        >
            <Typography variant="body1" sx={{ color: "#7c7e92" }}>{label}</Typography>
        </TableSortLabel>
    );

    return (
        <Box>
            <Grid container pb={1} mt={2.5}>
                <Grid item xs={10} style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        label="Название объекта"
                        name="name"
                        value={state.name}
                        onChange={handleFilterChange}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        style={{ width: 220, marginRight: 10 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setState(prevState => ({ ...prevState, page: 0, shouldFetch: true }))}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            style: {
                                borderRadius: "10px",
                            }
                        }}
                    />

                    <TextField
                        label="ФИО диспетчера"
                        name="dispatcherFio"
                        value={state.dispatcherFio}
                        onChange={handleFilterChange}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        style={{ width: 220, marginRight: 10 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setState(prevState => ({ ...prevState, page: 0, shouldFetch: true }))}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            style: {
                                borderRadius: "10px",
                            }
                        }}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                id="hideDismissedCheckbox"
                                checked={state.hideDismissed || false}
                                onChange={(event) => setState(prevState => ({
                                    ...prevState,
                                    hideDismissed: event.target.checked ? event.target.checked : null,
                                    shouldFetch: true,
                                }))}
                                style={{ color: '#7E5EA9' }}
                            />
                        }
                        label="Скрыть уволенных"
                    />
                </Grid>
                <Grid item xs={2} style={{ display: 'inline-block', alignItems: 'center' }}>
                    <ShowForPermission catalog={CategoryType.Dispatchers} permission={PermissionType.Add}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton sx={{ display: "flex" }} className="list-item" onClick={handleAddPopupToggle} >
                                <TmPlus fill="none" stroke="white" />&nbsp;
                                <Typography fontWeight="bold" pr={1}>
                                    &nbsp;Добавить объект
                                </Typography>
                            </IconButton>
                        </Box>
                    </ShowForPermission>
                </Grid>
            </Grid>

            <TableContainer sx={{ '.MuiTableCell-root': { py: 1.5 } }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {renderTableSortLabel('name', 'Название')}
                            </TableCell>
                            <TableCell>
                                {renderTableSortLabel('address', 'Адрес')}
                            </TableCell>
                            <TableCell>
                                {renderTableSortLabel('terminalNumber', '№ Терминала')}
                            </TableCell>
                            <TableCell>
                                {renderTableSortLabel('dispatcherFio', 'ФИО диспетчера')}
                            </TableCell>
                            <TableCell>
                                {renderTableSortLabel('dispatcherPhone', 'Телефон диспетчера')}
                            </TableCell>
                            <TableCell>
                                {renderTableSortLabel('dispatcherIsDismissed', 'Статус')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.departments.map((department) => (
                            <TableRow key={department.id}>
                                <TableCell
                                    sx={{
                                        fontWeight: "bold",
                                        fontSize: '14px',
                                        cursor: 'pointer',
                                        '&:hover': { color: '#9588e8' }
                                    }}
                                    onClick={() => handleDepartmentRowClick(department.id)}
                                >
                                    {department.name}
                                </TableCell>
                                <TableCell sx={{ fontSize: '14px' }}> {department.address} </TableCell>
                                <TableCell>
                                    {department.terminals.map((t) => (
                                        <Typography
                                            key={`terminal-${t.id}`}
                                            onClick={() => handleTerminalRowClick(t.id)}
                                            sx={{
                                                fontWeight: "bold",
                                                fontSize: '14px',
                                                mb: 0.5,
                                                cursor: 'pointer',
                                                '&:hover': { color: '#9588e8' }
                                            }}
                                        >
                                            {t.number}
                                        </Typography>
                                    ))}
                                </TableCell>
                                <TableCell >
                                    {department.dispatchers.map((d) => (
                                        <Box key={`dispatcher-${d.id}`}>
                                            <Typography
                                                key={d.id}
                                                onClick={() => handleDispatcherRowClick(d.id)}
                                                sx={{
                                                    display: 'inline',
                                                    whiteSpace: 'nowrap',
                                                    fontWeight: "bold",
                                                    fontSize: '14px',
                                                    mb: 0.5,
                                                    cursor: 'pointer',
                                                    '&:hover': { color: '#9588e8' }
                                                }}
                                            >
                                                {UserUtils.getFio(d)}
                                            </Typography>
                                        </Box>
                                    ))}
                                </TableCell>
                                <TableCell>
                                    {department.dispatchers.map((d) => (
                                        <Typography key={`dispatcher-phone-${d.id}`} sx={{ fontSize: '14px', mb: 0.5 }}>
                                            {d.phone}
                                        </Typography>
                                    ))}
                                </TableCell>
                                <TableCell>
                                    {department.dispatchers.map((d) => (
                                        <Typography key={`dispatcher-status-${d.id}`} sx={{ fontSize: '14px', mb: 0.5 }}>
                                            {d.isDismissed ? <DenyLabel>&nbsp;&nbsp;Уволен&nbsp;&nbsp;</DenyLabel> : <AcceptLabel>Работает</AcceptLabel>}
                                        </Typography>
                                    ))}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography>Всего: {state.dataCount}</Typography>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={state.dataCount}
                    rowsPerPage={state.rowsPerPage}
                    page={state.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={"Строк на странице:"}
                    labelDisplayedRows={({ from, to, count }: any) => `Показано ${from}-${to} из ${count}`}
                />
            </Box>
            <EditDepartmentDialog onClose={handleAddPopupToggle} open={isAddPopupOpen} isAdding={true} onSave={fetchData} companyId={company?.id} />
        </Box>
    );
};