import React from 'react';
import { Box, Typography } from '@mui/material';
import TmEllipses from '../../../icons/TmEllipses';

const TelemedLabel: React.FC = ({ }) => {
  return (
    <Box
      component="span"
      display="flex"
      justifyContent="center"
      alignItems="center"
      color="#10BFFF"
      sx={{
        width: '120px',
        minWidth: '120px',
        maxWidth: '120px',
        height: '27px',
        fontWeight: 'bold',
        backgroundColor: "#7ED2F133",
        borderRadius: "100px",
      }}>
      <Box sx={{ marginTop: '1px', display: 'flex' }}>
        <TmEllipses fill="none" />
      </Box>
      <Typography component="span" variant="body1" sx={{ fontWeight: 'bold', pl: '5px' }} fontSize={'16px'}>Telemed</Typography>
    </Box>
  );
}

export default TelemedLabel;
