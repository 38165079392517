import { AddMedicDto } from "./addMedicDto";
import { EditMedicDto } from "./editMedicDto";
import { MedicDetailsDto } from "./medicDetailsDto";
import { Gender } from "../enums/gender";

export interface EditMedicFormDto {
  id?: number;
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phoneNumber: string;
  gender: Gender | '';
  isDismissed: boolean;
  companyDepartmentPairs: CompanyDepartmentPair[];
  photoFile?: File;
  photoUrl?: string;
  isTelemed?: boolean;
}

export interface CompanyDepartmentPair {
  companyId: number | null;
  departmentId: number | null;
}

export const emptyEditMedicFormDto = (companyId?: number): EditMedicFormDto => {
  return {
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    phoneNumber: '',
    gender: '',
    isDismissed: false,
    companyDepartmentPairs: [],
    photoFile: undefined,
    photoUrl: undefined,
    isTelemed: false,
  }
};

export const mapToAddMedicDto = (formData: EditMedicFormDto): AddMedicDto => {
  return {
    firstName: formData.firstName,
    lastName: formData.lastName,
    middleName: formData.middleName,
    email: formData.email!,
    phoneNumber: formData.phoneNumber,
    gender: formData.gender,
    companyIds: [...new Set(formData.companyDepartmentPairs.map(pair => pair.companyId).filter((id): id is number => id !== null))],
    departmentIds: [...new Set(formData.companyDepartmentPairs.map(pair => pair.departmentId).filter((id): id is number => id !== null))],
    photoFile: formData.photoFile,
    isTelemed: formData.isTelemed,
  }
};

export const mapToEditMedicDto = (formData: EditMedicFormDto): EditMedicDto => {
  return {
    id: formData.id!,
    firstName: formData.firstName,
    lastName: formData.lastName,
    middleName: formData.middleName,
    email: formData.email!,
    phoneNumber: formData.phoneNumber,
    gender: formData.gender,
    isDismissed: formData.isDismissed,
    companyIds: [...new Set(formData.companyDepartmentPairs.map(pair => pair.companyId).filter((id): id is number => id !== null))],
    departmentIds: [...new Set(formData.companyDepartmentPairs.map(pair => pair.departmentId).filter((id): id is number => id !== null))],
    photoFile: formData.photoFile,
    photoUrl: formData.photoUrl,
    isTelemed: formData.isTelemed,
  }
};

export const mapToEditMedicFormDto = (dto: MedicDetailsDto): EditMedicFormDto => {
  return {
    id: dto.id!,
    firstName: dto.firstName,
    lastName: dto.lastName,
    middleName: dto.middleName,
    email: dto.email!,
    phoneNumber: dto.phoneNumber,
    gender: dto.gender,
    isDismissed: dto.isDismissed,
    companyDepartmentPairs: dto.companies
      ? dto.companies.flatMap<CompanyDepartmentPair>(company => 
          company.departments && company.departments.length > 0
            ? company.departments.map(department => ({
                companyId: company.id,
                departmentId: department.id
              }))
            : [{ companyId: company.id, departmentId: null }] // Ensure correct typing here
        )
      : [],
    photoUrl: dto.photoUrl,
    isTelemed: dto.isTelemed,
  }
};