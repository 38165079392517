import React from 'react';
import { Box } from '@mui/material';

interface DenyLabelProps {
  children: string;
  }

const DenyLabel: React.FC<DenyLabelProps> = ({ children }) => {
  return (
    <Box component="span" display="inline-block" color="#ec4a71" sx={{backgroundColor: "#fce4ea", borderRadius: "25px", paddingLeft: "15px", paddingRight: "15px", fontWeight: "bold", wordBreak: "break-all"}}>{children}</Box>
  );
}

export default DenyLabel;
